* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Nunito Sans', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}
.demo{
  margin: 16px 32px;
}
/* 
.demo ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.demo ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.demo ::-webkit-scrollbar-thumb {
  background-color: #dddd;
  border: 5px solid #d8d8d8;
} */

.tableWrapper {
  /* height: 100%;
  overflow: auto;
  flex: 1;
  margin: 0; */
  /* padding: 32px; */
  /* padding-top: 0; */
}

.rgt-cell:not(.rgt-row-edit):not(.rgt-row-not-selectable) {
  cursor: pointer;
}

.rgt-row-selected {
  background: #c8e0a8;
}

.rgt-row-hover {
  background: #f5f5f5;
}
.btn:hover {
  background-color: #5c0542;
}

.source-provider,
.button-section {
  display: flex;
  align-items: center;
  padding: 0 0 1em 0;
  width: 100%;
  justify-content: space-between;
}
.autocompelete-section,
.button-array,
.upload-section {
  display: inline-flex;
  align-items: center;
}
.button-array {
  justify-content: center;
  width: fit-content;
}
.button-section button {
  margin-left: 8px;
  min-width: fit-content;
}
/* .button-array {
  margin: -1em;
} */
.upload-section {
  width: fit-content;
}
.autocompelete-section > span {
  padding-right: 20px;
}
.upload-section button {
  min-width: 120px;
}
.rgt-columns-manager-button {
  min-width: 24px;
}

#red_color {
  color: red;
}
.page-heading{
  /* margin: 0px 0px 32px 18px !important; */
  margin-bottom: 8px !important;
}

.pretable{
  margin-bottom: 8px !important;
}

/* .rgt-cell-header-inner {
  padding: 0 20px;
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  width: 100%;
  /* background:// var(--rgt-background-color); */
  /* background: #B8E2FB; */


  /* overflow: hidden;
}  */

.MuiBox-root{
  padding: 0px !important;
}
