body {
  margin: 0px;

  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito Sans', 'sans-serif';
    -webkit-font-smoothing: antialiased;
}

.demo ::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.demo ::-webkit-scrollbar {
  width: 8px;
  height: 0px;
  background-color: #f5f5f5;
}

.demo ::-webkit-scrollbar-thumb {
  background-color: #dddd;
  border: 5px solid #d8d8d8;
}

label{
  cursor: pointer;
  margin-right:50px;
  /* border-bottom-style: dashed; */
  /* color: #21DA8C; */
}

#upload-file {
   opacity: 0;
   position: absolute;
   z-index: -1;
}

.PrivateTabIndicator-colorSecondary-5 {
  background-color: #21DA8C !important;
  position: absolute
}

.PrivateTabIndicator-root-3{
  height: 5px !important;
}

/* .rgt-footer-clear-selection-button > svg{
  display: none;
}

.rgt-cell {
  min-height: 40px !important;
  border-right: 1px solid #f7f7f7 !important;
  padding: 5px !important;
}

.rgt-wrapper{
  height: 60% !important;

}

.tableWrapper {
  height: 100%;
  padding: 20px;
  overflow: auto;
  flex: 1;
  margin: 0;
}

.rgt-cell:not(.rgt-row-edit):not(.rgt-row-not-selectable) {
  cursor: pointer;
}

.rgt-cell-header-inner  {
  background: #ecf7fe !important;
}

.rgt-cell-header {
  background: #ecf7fe !important;
}

.rgt-footer {
  position: relative;
  bottom: 0;
  width: 93%;
  top: 50;
}

.rgt-row-selected {
  background: #c8e0a8;
}

.rgt-row-hover {
  background: #f5f5f5;
}

.Toastify__toast-container {

  min-width: 320px;

}

.Toastify__toast--success {
  background: #edf7ed !important;
  color: #4CAF50;

}

.Toastify__close-button--success {
  color: #4CAF50 !important;
  opacity: 1;
}

.Toastify__toast--warning {
  background: #fff6d1 !important;
  color: #f5c60a;

}

.Toastify__close-button--warning {
  color: #f5c60a !important;
  opacity: 1;
}


.Toastify__toast--error {
  background: #fceceb !important;
  color: #F44336;

}

.Toastify__close-button--error {
  color: #F44336 !important;
  opacity: 1;
} */