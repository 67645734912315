:root {
    /* primary color set */
    --rgt-primary-color-pale: #ECF7FE;
    --rgt-primary-color-light: #87cdf8;
    --rgt-primary-color-main: #109cf1;
    --rgt-primary-color-dark: #0a6da9;
    --rgt-primary-color-background: #ecf7fe;
    /* secondary color set */
    --rgt-secondary-color-pale: #ffd7db;
    --grt-secondary-color-light: #b23939;
    --rgt-secondary-color-main: #ff5252;
    --rgt-secondary-color-dark: #b23939;
  
    /* universal background */
    --rgt-background-color: #ffffff;
    /* table header color */
    --rgt-header-background: var(--rgt-primary-color-background);
    /*shadow color*/
    --rgt-shadow-color: #82b4401a;
    /* --rgt-border- color: #C2C9D1;  */
    --rgt-border-color: rgba(0, 0, 0, 0.054);
    --rgt-button-color: var(--rgt-primary-color-main);
    --rgt-color1: #ffff;
    /* For disabled and unselectable values */
    --rgt-color2: #00000050;
    /* For overlay color */
    --rgt-color3: var(--rgt-primary-color-main);
    /* for highlight */
    --rgt-highlight: yellow;
    /* for hover on list item */
    --rgt-color5: #f5f5f5;
    /* border specs */
    --rgt-border:1px solid var(--rgt-border-color);
  }
  
  /* general */
  
  .rgt-text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .rgt-clickable {
    cursor: pointer;
  }
  
  .rgt-disabled {
    cursor: not-allowed;
  }
  
  .rgt-disabled-button {
    background: white!important;
    cursor: not-allowed !important;
  }
  
  .rgt-flex-child {
    flex: 1;
  }
  
  .rgt-wrapper * {
    box-sizing: border-box;
  }
  
  .rgt-wrapper ::-webkit-scrollbar-track {
    background-color: #FFFFFF!important;
  }
  
  .rgt-wrapper ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    background-color: #FFFFFF!important;
  }
  
  .rgt-wrapper ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
  
  /* elements */
  
  .rgt-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 70vh !important;
    min-height: 394px;
    overflow: auto;
    border-width: 0px!important; 
  }
  /* .tableWrapper {
    height: 100%;
    padding-top: 6px 0 6px 0 ;
    overflow: auto;
    flex: 1;
    margin: 0;
  }  */
  .rgt-container {
    background: var(--rgt-background-color);
    width: 100%;
    position: relative;
    height: 100%;
    /* border: 1px solid blue; */
  }
  
  /* header */
  .rgt-header-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      height: 36px;
  
    }
    
    .rgt-search-highlight {
      background: var(--rgt-highlight);
    }
    
    .rgt-columns-manager-wrapper {
      position: relative;
      z-index: 3;
      display: inline-flex;
      padding: 0;
    }
    
    .rgt-columns-manager-button {
      cursor: pointer;
      height: 26px;
      width: 26px;
      padding: 0;
      background: transparent;
      outline: none;
      border-radius: 50%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.2s ease;
    }
    
    .rgt-columns-manager-button:hover,
    .rgt-columns-manager-button-active {
      background: var(--rgt-color5);
    }
    
    .rgt-columns-manager-popover {
      display: inline-flex;
      flex-direction: column;
      transition: transform 0.1s ease-out;
      transform-origin: top right;
      transform: scale(0);
      padding: 10px 0px;
      position: absolute !important;
      right: 50% !important;
      top: 80% !important;
      background: var(--rgt-background-color) !important;
      border-radius: 18px !important;
      box-shadow: 1px 1px 4px 0px var(--rgt-shadow-color) !important;
      min-width: 200px;
    }
    
    .rgt-columns-manager-popover-open {
      transform: scale(1);
    }
    
    .rgt-columns-manager-popover-row {
      display: flex;
      flex: 1;
      justify-content: space-between;
      position: relative;
      font-size: 14px;
      align-items: center;
    }
    
    .rgt-columns-manager-popover-title {
      padding: 16px 0px 8px 32px !important;
      font-weight: 700 !important;
      margin-bottom: 10px;
      white-space: nowrap;
      font-size: 12px !important;
      color:#595959 !important;
    }
    
    .rgt-columns-manager-popover-row > label {
      padding: 5px 32px 5px 32px !important;
      width: 100%;
    }
    
    .rgt-columns-manager-popover-row > input {
      margin: 0;
      position: absolute;
      right: 20px;
      pointer-events: none;
    }
    
    .rgt-columns-manager-popover-row:hover {
      background: var(--rgt-color5);
    }
    
    .rgt-columns-manager-popover-body {
      display: inline-flex;
      flex-direction: column;
      max-height: 290px;
      height: 100%;
      width: 100%;
      overflow: auto;
      max-width: 300px;
      color: #A0A0A0;
      font-weight: 400;
      font-size: 14px;
    }
    
    .rgt-search-container {
      width: 100%;
      z-index: 1;
      flex: 1;
      display: inline-flex;
      padding: 10px 10px 10px 20px;
    }
    
    .rgt-search-label {
      line-height: 30px;
      font-weight: 500;
      font-size: 16px;
      margin-right: 5px;
      display: inline-flex;
      align-items: center;
    }
    
    .rgt-search-icon {
      font-size: 22px;
      transform: rotate(-35deg);
      display: inline-block;
      margin-right: 5px;
      color: var(--rgt-color2);
    }
    
    .rgt-search-input {
      width: 100%;
      line-height: 30px;
      margin-right: 10px;
      flex: 1;
      border: none;
      outline: none;
      font-size: 16px;
      padding: 0;
    }
  
  
  .rgt-cell {
    background: var(--rgt-background-color);
    display: flex;
    height: 100%;
    align-items: center;
    border-top: var(--rgt-border);
    min-height: 38px;
    border-bottom: 0;
    /* border: 1px solid red; */
  }
  
  
  .rgt-cell-inner {
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin: 0;
  }
  
  .rgt-cell-header {
    display: flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    min-height: 38px;
    max-height: 38px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #595959;
    border-bottom: 0px;
  }
  
  .rgt-cell-header-virtual-col {
    border-bottom: var(--rgt-border);
    background: var(--rgt-background-color);
    z-index: 2;
  }
  
  .rgt-cell-header-inner {
    padding: 0px;
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    width: 100%;
    background: var(--rgt-background-color);
    overflow: hidden;
  }
  .rgt-cell-header-inner:nth-child(even) {
    background: var(--rgt-color3);
  }
  
  .rgt-cell-header-inner-not-pinned-right {
    border-right: 0px;
  }
  
  .rgt-cell-header-inner-checkbox {
    padding: 0px;
    justify-content: left;
  }
  
  .rgt-placeholder-cell {
    position: relative;
    border-radius: 2px;
    height: 20px;
    width: 100%;
    display: inline-block;
    margin: 0 20px;
    overflow: hidden;
    background-color: #eee;
  }
  
  .rgt-placeholder-cell::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }
  
  .rgt-resize-handle {
    height: 100%;
    width: 10px;
    z-index: 1;
    cursor: w-resize;
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .rgt-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background: var(--rgt-background-color);
    z-index: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 42px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #8A8A8A;
    margin: 5px;
    border-top: 0px;
  }
  
  .rgt-footer-items-information {
    padding-left: 0px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    height: 100%;
  }
  
  .rgt-footer-clear-selection-button {
    display: inline-flex;
    margin-left: 5px;
  }
  
  .rgt-footer-page-size {
    display: flex;
    align-items: center;
  }
  
  .rgt-footer-page-size-select {
    cursor: pointer;
    margin-right: 20px;
    margin-left: 10px;
    border-radius: 4px;
    border-color: var(--rgt-border-color);
    padding: 2px 0px;
  }
  
  .rgt-footer-page-input {
    padding: 0px 0px 0px 5px;
    outline: none;
    flex: 1;
    max-width: 52px;
    line-height: 22px;
    margin: 0 10px -2px;
    border-radius: 4px;
    border:0px
  }
  
  .rgt-footer-right-container {
    display: inline-flex;
    align-items: center;
    /* padding: 12px; */
    white-space: nowrap;
    height: 100%;
  }
  
  .rgt-footer-pagination {
    display: flex;
    height: fit-content;
    align-items: center;
  }
  
  .rgt-footer-pagination-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
  }
  
  .rgt-footer-pagination-button {
   
    border: none;
    /* border-radius: 4px; */
    cursor: pointer;
    display: block;
    outline: none;
    position: relative;
    background-color: white;
    display: flex;
    align-items: center;
    box-shadow: none;
    min-width: max-content;
  }
  .rgt-footer-pagination-button:active {
    transform: translate(-1px, 1px);
    box-shadow: 0px 2px 4px var(--rgt-shadow-color);
  }
  .rgt-cell-checkbox {
    padding: 0 ;
    box-sizing: border-box;
    background: var(--rgt-background-color);
    justify-content: left;
  }
  
  .rgt-sort-icon {
    font-size: 12px;
    margin-left: 7px;
    display: inline-flex;
    color: #21DA8C;
  }
  
  .rgt-container-overlay {
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 700;
    color: #a3a3a3 !important;
    /* var(--rgt-color3); */
    height: 100%;
  }
  
  .rgt-column-sort-ghost {
    border-left: var(--rgt-border);
    border-right: var(--rgt-border);
    z-index: 2;
  }
  
  .rgt-cell-editor-inner {
    position: relative;
    height: 30px;
    width: 100%;
  }
  
  .rgt-cell-editor-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border: none;
    border-bottom: var(--rgt-border);
    outline: none;
    font-size: 16px;
    padding: 0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .rgt-cell-header-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  
  .rgt-cell-header-not-sticky {
    position: relative;
  }
  
  .rgt-cell-header-pinned {
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    background-color: #fff;
  }
  
  .rgt-cell-header-pinned-left {
    left: 0;
  }
  
  .rgt-cell-header-pinned-right {
    right: 0;
    padding: 0 24px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }
  
  .rgt-cell-pinned {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
  }
  
  .ne {
    color: red;
  }
  .rgt-cell-pinned-left {
    left: 0;
  }
  
  .rgt-cell-pinned-right {
    right: 0;
  
  }
  .rgt-cell-header {
    min-height: 38px;
    max-height: 38px;
  }
  /* button{
      background: rgb(243, 243, 243);
      outline: none;
      cursor: pointer;
      padding: 4px;
      display: inline-flex;
      border: none;
      border-radius: 50%;
      box-shadow: rgb(0 0 0 / 30%) 1px 1px 2px 0px;
  } */
  /* button>svg{
  height: 20px;
  width: 20px;
  } */