.combobox {
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
    height: 100%;
    width: 95%;
    font-family: inherit;
    font-size: 16px;     
    border: none;
    outline: none;
    margin-bottom: -20px;
    
    
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 28px;
  width: 175px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-size: 16px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}